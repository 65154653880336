import React from "react";

class DealerLoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                ZipCode: ""
            },
            poi: ""
        };
    }
    handleInputKeyUp = async e => {
        const newValue = e.target.value.replace(/\D/g, '');
        e.target.value = newValue;
        if (e.target.value.length === 5) {
            var jsonData = JSON.stringify({
                "ZipCode": this.state.values.ZipCode
            });
            const results = await fetch(process.env.REACT_APP_DEALER_LOC, {
                method: "POST",
                body: jsonData
            });
            const dataOutput = await results.json();
            if (dataOutput.data) {
                this.setState({
                    poi: dataOutput.data
                });
            } else {
                this.setState({ poi: "" });
            }
        }
    }
    handleInputChange = e =>
        this.setState({
            values: { ...this.state.values, [e.target.name]: e.target.value }
        });
    submitHandler = e => {
        e.preventDefault();
        return false;
    }
    render() {
        return (
            <>
                <form id="dealer-form" onSubmit={this.submitHandler} method="post">
                    <div className="mt-1">
                        <div className="input-group mb-3">
                            <input type="text" id="ZipCode" name="ZipCode" className="form-control" placeholder="Enter ZIP Code" maxLength="5" required aria-label="Enter ZIP Code" value={this.state.values.ZipCode} onKeyUp={this.handleInputKeyUp} onChange={this.handleInputChange} />
                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                </form>
                <div id="dealer-locations" className={this.state.poi !== "" ? 'active' : ''}>
                    {this.state.poi !== "" && this.state.poi.map(function (item) {
                        return (<>
                            <div className="dealer card" key={item.POIIdent}>
                                <h4><a href={item.InternetAddress} target="_blank" rel="noreferrer">{item.POIName}</a></h4>
                                <div className="d-flex justify-content-between">
                                    <div>{item.Address}<br /> {item.City}, {item.State} {item.ZipCode}</div>
                                    <div><b>{item.Distance}</b></div>
                                </div>
                                <div> Phone: <a href={`tel:${item.Phone.replace(/\s/g, '')}`}>{item.Phone}</a></div>
                                <a className="btn btn-small mt-2" href={`https://maps.google.com/maps?q=${item.POIName.replace(/\s/g, '+')}`} target="_blank" rel="noreferrer">Get Location</a>
                            </div>
                        </>
                        )
                    })
                    }
                </div>
            </>
        );
    }
}
export default (DealerLoc);