import React from "react";
import { withTranslation } from 'react-i18next';
import HondaAuto from '../assets/honda_auto.svg';
import Acura from '../assets/acura.svg';
import HondaPowersport from '../assets/honda_powersport.svg';
import HondaPowerEquipment from '../assets/honda_power_equipment.svg';
import HondaJet from '../assets/honda_jet.svg';
import HondaRacing from '../assets/honda_racing.svg';
import HondaEngine from '../assets/honda_engine.svg';
import HondaMarine from '../assets/honda_marine.svg';
import PrivacyOptions from '../assets/privacyoptions.svg';

class Footer extends React.Component {
    render() {
        const year = new Date().getFullYear();
        return (
            <>
                <footer>
                    <div className="container-wrapper">
                        <section className="copyright">
                            <div className="rights"><a href="http://www.honda.com" target="_blank" rel="noopener noreferrer">©{year} American Honda Motor Co.</a>, Inc. All information contained herein applies to U.S. vehicles only.  For non-U.S.-distributor information go to <a href="http://world.honda.com/link/" target="_blank" rel="noopener noreferrer">world.honda.com</a>. App store is a service mark of Apple Inc. Apple and the Apple logo are trademarks of Apple Inc. registered in the U.S. and other countries. Google Play and the Google Play logo are trademarks of Google LLC.</div>
                            <div className="links">
                                <a href="https://www.honda.com/privacy/privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Notice</a>
                                <a href="https://automobiles.honda.com/images/downloads/prop-65-consumer-website-statement.pdf" target="_blank" rel="noopener noreferrer">Prop 65 Info</a>
                                <a href="https://www.honda.com/privacy/connected-product-privacy-notice" target="_blank" rel="noopener noreferrer">Vehicle Data Privacy</a>
                                <a href="https://www.honda.com/privacy/sms-terms" className="last" target="_blank" rel="noopener noreferrer">SMS Terms</a>
                                <a href="https://www.honda.com/privacy/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
                                <a href="https://www.honda.com/privacy/your-privacy-choices" target="_blank" rel="noopener noreferrer">Your Privacy Choices <img alt="Your Privacy Choices" src={PrivacyOptions} width="30" height="14" /></a>
                            </div>
                        </section>
                        <section className="brands">
                            <div>
                                <a href="http://automobiles.honda.com/" className="honda-logo" aria-label="Honda Automobiles" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaAuto} alt="Honda Automobiles" /></a>
                                <a href="http://www.acura.com/" aria-label="Acura" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Acura} alt="Acura" /></a>
                                <a href="http://powersports.honda.com" aria-label="Honda Power Sports" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaPowersport} alt="Honda Power Sports" /></a>
                                <a href="http://powerequipment.honda.com" aria-label="Honda Power Equipment" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaPowerEquipment} alt="Honda Power Equipment" /></a>
                                <a href="http://www.hondajet.com" aria-label="Honda Jet" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaJet} alt="Honda Jet" /></a>
                                <a href="http://engines.honda.com" aria-label="Honda Engines" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaEngine} alt="Honda Engines" /></a>
                                <a href="http://marine.honda.com" aria-label="Honda Marine" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaMarine} alt="Honda Marine" /></a>
                                <a href="http://hpd.honda.com" aria-label="Honda Racing" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={HondaRacing} alt="Honda Racing" /></a>
                            </div>
                        </section>
                        <section className="cookies">
                            <div className="rights">We and third parties use cookies and similar tools to enhance and track your experience on our Sites, conduct analytics, and personalize marketing to you. By using the Sites, you agree to our use of these technologies. For more information, visit the <a href="https://www.honda.com/privacy/privacy-notice#cookies" className="text-link" target="_blank" rel="noopener noreferrer">Cookies section</a> within our Privacy Notice. To manage your Cookie Preferences, <button type="button" className="ot-sdk-show-settings text-link">click here.</button></div>
                        </section>
                    </div>
                </footer>

            </>
        );
    }
}
export default withTranslation()(Footer);