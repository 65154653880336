import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import Modal from 'react-bootstrap/Modal';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min";
import { Navigation, EffectFade, Autoplay } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import GLightbox from 'glightbox';
import imagesLoaded from "imagesloaded";
import counterUp from 'counterup2';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { useTranslation } from 'react-i18next';

import Footer from "../components/Footer";
import Videos from "../components/Videos";
import DealerLoc from "../components/DealerLoc";

import PreLoader from '../assets/moto_loader.gif';
import MotoLogo from '../assets/logo.svg';
import MotoHeroLogo from '../assets/white_logo.svg';
import HeroMoto from '../assets/moto_revealmaster_v1.mp4';
import HeroMotoMobile from '../assets/moto_revealmaster_mobile_v1.mp4';
import TopMoto from '../assets/top_motocompacto.png';
import MerchImg from '../assets/moto_helmet.jpg';
import PlugIcon from '../assets/plug_icon.svg';
import HondaIcon from '../assets/honda_icon.svg';
import Smart from '../assets/ride_smart.jpg';
import RedBall from '../assets/red_ball.png';
import BlueBall from '../assets/blue_ball.png';
import GreenBall from '../assets/green_ball.png';
import FeaturesBkg from '../assets/moto_feature_bkg.jpg';
import WaterIcon from '../assets/water_icon.svg';
import WeightIcon from '../assets/weight_icon.svg';
import SizeIcon from '../assets/size_icon.svg';
import AppleBadge from '../assets/apple-badge.svg';
import GoogleBadge from '../assets/google-badge.svg';
// Load Local Video
import localVideo from '../assets/honda_mc_031_mix1_1080p.mp4';

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

export function Home() {
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [showXrayExpand, setXrayExpand] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
    const InitEffect = useRef(false);
    const { t } = useTranslation();
    const GalleryAssets = t("gallery.assets", {
        returnObjects: true
    });
    const ExpressAssets = t("express.assets", {
        returnObjects: true
    });
    const FitAssets = t("fit.assets", {
        returnObjects: true
    });
    const XrayAssets = t("xray.assets", {
        returnObjects: true
    });
    const SpecsData = t("specs.data", {
        returnObjects: true
    });
    const OpeningData = t("opening.data", {
        returnObjects: true
    });
    const HandleXrayEvent = id => {
        setXrayExpand(id);
    };
    const XrayExpand = () => (
        <div className="item animated fadeInUp">
            <h3 className="headline">{XrayAssets[showXrayExpand - 1].title}</h3>
        </div>
    );
    useEffect(() => {
        window.scrollTo(0, 0);
        const Init = () => {
            const ScrollController = new ScrollMagic.Controller();
            const counterELements = document.querySelectorAll('.counter');
            const HeroLogo = document.getElementById("hero-logo");
            const HeroTitle = document.getElementById("title-hero");
            const VideoHero = document.getElementById("intro-video");
            const canvas = document.getElementById("design-assemble");
            const NavBar = document.getElementById("header-navbar");
            const Express = document.getElementById("express");
            const context = canvas.getContext("2d");
            const frameCount = 235;
            var isMobile = false;
            const images = [];
            const motocomp = {
                frame: 0
            };
            const SpecsCounter = e => {
                for (var i = 0; i < counterELements.length; ++i) {
                    counterUp(counterELements[i], {
                        duration: 2000,
                        delay: 10
                    });
                }
            };
            const RenderFrames = () => {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(images[motocomp.frame], 0, 0);
            }
            const HandleScroll = () => {
                const currentScrollPos = window.pageYOffset;
                if (currentScrollPos > 600) {
                    NavBar.classList.add('scrolled');
                } else if (!NavBar.classList.contains('finished')) {
                    NavBar.classList.remove('scrolled');
                }
                if (currentScrollPos < 300) {
                    NavBar.classList.add('active');
                } else {
                    NavBar.classList.remove('active');
                    setIsReady(true);
                }
            }
            const handleResize = () => {
                if ((window.innerHeight > window.innerWidth) && window.matchMedia("(max-width: 991px)").matches) {
                    setIsMobileDevice(true);
                }
                if ((window.innerHeight > window.innerWidth) && window.matchMedia("(max-width: 767px)").matches) {
                    isMobile = true;
                } else {
                    setIsMobileDevice(false);
                    isMobile = false;
                }
            }
            // Portiate View and Mobile
            if ((window.innerHeight > window.innerWidth) && window.matchMedia("(max-width: 991px)").matches) {
                setIsMobileDevice(true);
            }
            if ((window.innerHeight > window.innerWidth) && window.matchMedia("(max-width: 767px)").matches) {
                isMobile = true;
                canvas.width = 1380;
                canvas.height = 1152;
            } else {
                canvas.width = 2048;
                canvas.height = 1152;
            }
            window.addEventListener("scroll", HandleScroll, { passive: true });
            window.addEventListener('resize', handleResize, { passive: true });
            const FramesAnimation = () => {
                gsap.to(motocomp, { frame: frameCount - 1, snap: "frame", ease: "none", immediateRender: true, scrollTrigger: { scrub: 0, trigger: "#design", end: "+=" + (window.innerHeight * 4), pin: true }, onUpdate: RenderFrames });
                images[0].onload = RenderFrames;
            }
            const ScrollAnim = (json) => {
                var Scrolls = new ScrollMagic.Scene({ triggerElement: json.element, triggerHook: json.trigger, duration: json.duration }).addTo(ScrollController);
                if (json.target) {
                    var elements = document.querySelectorAll(json.target);
                    Scrolls.on('enter', function (e) {
                        for (var i = 0; i < elements.length; ++i) {
                            var setAnimation = (elements[i].dataset.ease) ? elements[i].dataset.ease : 'fadeInUp';
                            elements[i].classList.add(setAnimation);
                        }
                    });
                    Scrolls.on('leave', function (e) {
                        for (var i = 0; i < elements.length; ++i) {
                            var setAnimation = (elements[i].dataset.ease) ? elements[i].dataset.ease : 'fadeInUp';
                            var setStatic = (elements[i].classList.contains('static')) ? 1 : 0;
                            if (!setStatic) {
                                elements[i].classList.remove(setAnimation);
                            }
                        }
                    });
                }
                if (json.offset) {
                    Scrolls.offset(json.offset);
                }
                if (json.tween) {
                    Scrolls.setTween(json.tween);
                }
                if (json.pin) {
                    Scrolls.setPin(json.element);
                }
                if (json.callback) {
                    Scrolls.on('start', e => {
                        json.callback(e);
                    });
                }
            }
            const LoadDesignAssets = () => {
                for (let i = 0; i < frameCount; i++) {
                    const img = new Image();
                    if (isMobile) {
                        img.src = require(`../assets/design/mobile/Moto_Web_Anim_Spin_${(i).toString().padStart(4, '0')}.jpg`);
                    } else {
                        img.src = require(`../assets/design/Moto_Web_Anim_Spin_${(i).toString().padStart(4, '0')}.jpg`);
                    }
                    images.push(img);
                }
            };

            VideoHero.addEventListener("canplay", function (e) {
                setIsVideoLoading(false);
                HeroLogo.classList.remove("fade");
                HeroTitle.classList.remove("fadein");
                NavBar.classList.remove('scrolled');
                NavBar.classList.remove('active');
                NavBar.classList.remove('finished');
            }, { passive: true });

            VideoHero.addEventListener("timeupdate", function (e) {
                if (this.currentTime > 1.4) {
                    HeroLogo.classList.add("fade");
                }
                if (this.currentTime > 10.5) {
                    HeroTitle.classList.add("fadein");
                    NavBar.classList.add('scrolled');
                    NavBar.classList.add('active');
                    NavBar.classList.add('finished');
                }
            });
            LoadDesignAssets();
            GLightbox();
            const imgLoad = imagesLoaded('#root', { background: true });
            imgLoad.on('done', () => {
                setTimeout(function () {
                    const BallAnim = gsap.fromTo(['#red-ball', '#green-ball', '#blue-ball'], { yPercent: 0 }, { yPercent: 200, ease: 'none' });
                    const PhoneAnim = gsap.fromTo('#phones', { scale: 0.8 }, { scale: 1, ease: 'none' });
                    // Handle Inview Area   
                    ScrollAnim({ 'element': '#features', 'duration': '100%', 'trigger': 0.7, 'target': '#features .inview' }); // Features 
                    ScrollAnim({ 'element': '#fit', 'duration': '100%', 'trigger': 0.7, 'target': '#fit .inview' }); // Fit 
                    ScrollAnim({ 'element': '#quality', 'duration': '100%', 'trigger': 0.7, 'target': '#quality .inview' }); // Quality 
                    ScrollAnim({ 'element': '#gallery', 'duration': '100%', 'trigger': 0.7, 'target': '#gallery .inview' }); // Gallery   
                    ScrollAnim({ 'element': '#smart', 'duration': '100%', 'trigger': 0.7, 'target': '#smart .inview', }); // Smart
                    ScrollAnim({ 'element': '#merch', 'duration': '100%', 'trigger': 0.7, 'target': '#merch .inview', }); // Merch 
                    ScrollAnim({ 'element': '#opening', 'duration': '100%', 'trigger': 0.7, 'target': '#opening .inview', }); // Opening
                    ScrollAnim({ 'element': '#specs', 'duration': '100%', 'trigger': 0.7, 'target': '#specs .inview' }); // Specs  
                    ScrollAnim({ 'element': '#xray', 'duration': '100%', 'trigger': 0.7, 'target': '#xray .view' }); // Specs  
                    ScrollAnim({ 'element': Express, 'duration': Express.clientHeight, 'trigger': 0.7, 'target': '#express .inview' }); // Express   
                    ScrollAnim({ 'element': '#specs .specs-wrapper', 'duration': '100%', 'trigger': 0.7, 'target': '#specs .specs-wrapper', 'callback': SpecsCounter }); // Spec 
                    ScrollAnim({ 'element': '#smart', 'duration': '100%', 'tween': BallAnim, 'trigger': 0.3 });
                    ScrollAnim({ 'element': '#smart', 'duration': '100%', 'tween': PhoneAnim, 'trigger': 0.7 });
                }, 1000);
                setTimeout(function () {
                    FramesAnimation();
                }, 3000);
            })
        }
        if (process.env.NODE_ENV === 'development') {
            if (InitEffect.current) {
                Init();
            }
            return () => {
                InitEffect.current = true
            }
        } else {
            Init();
        }
    }, []);
    return (
        <>
            {isVideoLoading && (
                <div id="loader-wrapper">
                    <div className="loader"><img className="img-fluid" src={PreLoader} alt={t('global.alt')} width="1920" height="1080" /></div>
                </div>
            )}
            <div id="home" className={isVideoLoading ? 'd-none' : ''}>

                <section id="hero">
                    <nav id="header-navbar" className="navbar fixed-top" data-ease="active">
                        <div className="container-fluid">
                            <a className="navbar-brand mx-4" href="/">
                                <img className="img-fluid" src={MotoLogo} alt={t('global.alt')} width="300" height="29" />
                            </a>
                            <div className="d-flex align-items-center">
                                <a href={t('hero.orderlink')} className="btn btn-theme btn-red ms-1" target="_blank" rel="noopener noreferrer">{t('hero.order')}</a>
                            </div>
                        </div>
                    </nav>
                    <video id="intro-video" src={isMobileDevice ? HeroMotoMobile : HeroMoto} playsInline="1" preload="auto" autoPlay="1" muted="1" alt={t('hero.title')} />
                    <div id="hero-logo" className="animated"><img className="img-fluid" src={MotoHeroLogo} alt={t('global.alt')} width="650" height="62" /></div>
                    <div id="title-hero" className="intro-wrapper animated container-fluid">
                        <div className="headline-wrapper mx-4">
                            <h1 className="display-1">{t('hero.title')}</h1>
                            <h2 className="display-1 red">{t('hero.subtitle')}</h2>
                        </div>
                    </div>
                    <div className="arrows"></div>
                </section>

                <section id="combined-section" className="section-content" style={{ backgroundImage: `url(${FeaturesBkg})` }}>
                    <div id="features">
                        <div className="container">
                            <div className="wrapper">
                                <h2 className="headline">
                                    <div className="inview animated" data-ease="fadeInRight">{t('features.title')}</div>
                                    <div className="red inview animated delay-1 mt-2" data-ease="fadeInLeft">{t('features.subtitle')}</div>
                                </h2>
                                <div className="content inview animated delay-2" data-ease="fadeInUp">{t('features.text')}</div>
                            </div>
                        </div>
                    </div>
                    <div id="trailer">
                        <div className="wrapper">
                            <Videos id="player-1" type="local" url={localVideo} title={t('trailer.title')} image={t('trailer.image')} />
                        </div>
                    </div>
                </section>

                <div className={isReady ? 'visible' : 'invisible'}>
                    <section id="fit" className="section-content">
                        <div className="container-fluid">
                            <div className="row g-2">
                                <div className="col-md-5 d-md-flex align-items-center">
                                    <div className="fit-content">
                                        <h2 className="headline inview animated" dangerouslySetInnerHTML={{ __html: t('fit.title') + ' <div class="mt-2">' + t('fit.subtitle') + '</div>' }} />
                                        <div className="content inview animated delay-1">{t('fit.text')}</div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <SwiperComponent
                                        loop speed={800} effect={"fade"} navigation={{
                                            prevEl: '.swiper-prev-arrow',
                                            nextEl: '.swiper-next-arrow',
                                        }} modules={[Navigation, EffectFade]} className="inview animated static delay-2">
                                        {FitAssets.map(function (item) {
                                            return (
                                                <SwiperSlide key={item.id}><img className="img-fluid" src={require(`../assets/fit/${item.path}`)} alt={item.title} title={item.title} width="1315" height="1018" /></SwiperSlide>
                                            );
                                        })}
                                        <div className="swiper-arrows">
                                            <div className="swiper-arrow swiper-prev-arrow"><svg className="img-fluid" width="66px" height="115px" viewBox="0 0 66 115"><path d="M2.4,57.2C19.8,39.9,37.5,22.2,55.2,4.5c2.4,2.4,5.1,5,8.1,7.9C48.4,27.2,33.4,41.9,18.1,57C33.7,72.4,48.7,87.2,64,102.3c-3.3,3.1-6.1,5.6-8.8,8C37.7,92.8,20.1,75,2.4,57.2z" /></svg></div>
                                            <div className="swiper-arrow swiper-next-arrow"><svg className="img-fluid" width="66px" height="115px" viewBox="0 0 66 115"><path d="M63.6,57.3C46.3,39.9,28.5,22.2,10.9,4.5c-2.4,2.4-5.1,5-8.1,7.9C17.7,27.1,32.7,42,47.9,57c-15.7,15.4-30.7,30.2-46,45.2c3.3,3.1,6.1,5.6,8.7,8.1C28.2,92.8,45.9,75,63.6,57.3z" /></svg></div>
                                        </div>
                                    </SwiperComponent>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="design" className="section-content">
                        <div className="design-wrapper">
                            <canvas id="design-assemble" />
                        </div>
                        <div className="design-content">
                            <h2 className="headline display-2 red" dangerouslySetInnerHTML={{ __html: t('design.title') + ' <div class="black">' + t('design.subtitle') + '</div>' }} />
                            <div className="content">{t('design.text')}</div>
                        </div>
                        <div className="design-content-right text-center">
                            <div className="d-flex d-md-block align-items-center justify-content-center">
                                <div className="group">
                                    <div className="upper red mt-md-5">{t('design.dimension.upper')}</div>
                                    <div className="value slim" dangerouslySetInnerHTML={{ __html: t('design.dimension.value') }} />
                                    <div className="lower red">{t('design.dimension.lower')}</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="specs" className="section-content">
                        <div className="row g-0">
                            <div className="col-md-6 pe-lg-5 inview animated static moto-top"><img className="img-fluid" src={TopMoto} alt={t('global.alt')} width="1200" height="2500" /></div>
                            <div className="col-md-6 specs-wrapper static" data-ease="active">
                                <h2 className="text-center headline inview animated delay-1 static">{t('specs.title')}</h2>
                                <h5 className="subtitle d-flex align-items-center justify-content-center inview animated delay-2 static"><img className="img-fluid" src={PlugIcon} alt={t('global.alt')} /><span dangerouslySetInnerHTML={{ __html: t('specs.subtitle') }} /></h5>
                                <div className="row row-cols-2 g-0 inview animated static delay-3">
                                    {SpecsData.map(function (item) {
                                        return (
                                            <div className="spec" key={item.id}>
                                                <svg viewBox="0 0 36 36" className="chart"><path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" /><path className="circle" strokeDasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" /></svg>
                                                <div className="spec-data">
                                                    <div className="upper">{item.upper}</div>
                                                    <div className="value"><span className={item.counter}>{item.value}</span><span className="sub" dangerouslySetInnerHTML={{ __html: item.subvalue }} /></div>
                                                    <div className="lower" dangerouslySetInnerHTML={{ __html: item.lower }} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="spec">
                                        <div className="spec-content">
                                            <div className="list d-flex align-items-center"><span className="img-wrapper"><img className="img-fluid" src={WaterIcon} alt={t('specs.water')} /></span><span>{t('specs.water')}</span></div>
                                            <div className="list d-flex align-items-center"><span className="img-wrapper"><img className="img-fluid" src={WeightIcon} alt={t('specs.weight')} /></span><span>{t('specs.weight')}</span></div>
                                            <div className="list d-flex align-items-center"><span className="img-wrapper"><img className="img-fluid" src={SizeIcon} alt={t('specs.size')} /></span><span>{t('specs.size')}</span></div>
                                            <div className="list d-flex align-items-center"><span>{t('specs.age')}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="quality" className="section-content">
                        <div className="container-fluid">
                            <div className="wrapper">
                                <h3 className="headline inview animated delay-1" dangerouslySetInnerHTML={{ __html: t('quality.title') }} />
                                <div className="content inview animated delay-2">
                                    <p>{t('quality.text')} Visit <a href="#dealer" onClick={handleModalShow}>Your Dealer</a> today to see and experience it for yourself.</p>
                                    <div className="cta-action inview animated mt-4 delay-3"><a href={t('hero.orderlink')} className="btn btn-theme btn-red ms-1" target="_blank" rel="noopener noreferrer">{t('quality.order')}</a></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="gallery" className="section-content">
                        <div className="wrapper">
                            <div className="row row-cols-1 row-cols-2 row-cols-lg-3 g-0 inview animated static delay-1">
                                {GalleryAssets.map(function (item) {
                                    return (
                                        <div className="col" key={item.id}>
                                            <div className="card">
                                                <a href={require(`../assets/gallery/${item.path}`)} className="glightbox" data-gallery="Gallery" data-type="image">
                                                    <img className="img-fluid" src={require(`../assets/gallery/${item.thumb}`)} alt={item.title} title={item.title} width="1000" height="563" />
                                                    <div className="zoom"><span>+</span></div></a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section id="xray" className="section-content">
                        <div className="view" data-ease="active">
                            <div className="xray-wrapper">
                                <div id="xray-assemble">
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet={require(`../assets/xray/motocompacto_bike_xray_setup_pieces_02.jpg`)} width="1920" height="1080" />
                                        <img className="img-fluid w-100" src={require(`../assets/xray/mobile/motocompacto_bike_xray_setup_pieces_02.jpg`)} alt={t('global.title')} width="1380" height="1080" />
                                    </picture>
                                    {XrayAssets.map(function (item) {
                                        return (
                                            <div className={`item ${showXrayExpand === item.id ? 'active' : ''}`} key={item.id}>
                                                <picture>
                                                    <source media="(min-width: 768px)" srcSet={require(`../assets/xray/${item.path}`)} width="1920" height="1080" />
                                                    <img className="img-fluid w-100" src={require(`../assets/xray/mobile/${item.path}`)} alt={item.title} width="1380" height="1080" />
                                                </picture>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="xray-dots">
                                {XrayAssets.map(function (item) {
                                    var setStyle = {};
                                    var coord = item.plots.split(',');
                                    if (coord[0] > 0) {
                                        setStyle = { top: coord[0] + '%', left: coord[1] + '%' };
                                    }
                                    return (
                                        <div className={`item ${showXrayExpand === item.id ? 'active' : ''}`} key={item.id} style={setStyle} onClick={() => HandleXrayEvent(item.id)} >
                                            <div className="plus">+</div>
                                            <div className="dot" dangerouslySetInnerHTML={{ __html: item.target }} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="xray-content d-lg-none">
                            {showXrayExpand ? <XrayExpand /> : null}
                        </div>
                    </section>

                    <section id="express" className="section-content">
                        <div className="express-wrapper inview animated static">
                            <SwiperComponent
                                loop
                                allowTouchMove={false}
                                simulateTouch={false}
                                pauseonmouseenter="false" speed={500} effect={"fade"} autoplay={{
                                    delay: 1500,
                                    disableOnInteraction: false
                                }} modules={[Autoplay, EffectFade]} className="inview animated static delay-2">
                                {ExpressAssets.map(function (item) {
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <picture>
                                                <source media="(min-width: 768px)" srcSet={require(`../assets/express/${item.path}`)} width="2000" height="1124" />
                                                <img className="img-fluid w-100" src={require(`../assets/express/mobile/${item.path}`)} alt={item.title} width="1380" height="1124" />
                                            </picture>
                                        </SwiperSlide>
                                    );
                                })}
                            </SwiperComponent>
                            <div className="express-content text-center">
                                <div className="icons inview animated static mx-auto"><svg className="img-fluid" viewBox="0 0 136 139"><path fillRule="evenodd" fill="#c10000" d="M119.503,106.689 C114.643,106.689 110.292,108.872 107.377,112.309 C106.997,112.761 106.641,113.226 106.313,113.714 L106.313,83.945 C106.313,79.745 104.425,75.764 101.170,73.114 L85.017,59.940 C82.526,57.903 79.408,56.796 76.190,56.796 L14.564,56.796 C6.849,56.796 0.594,63.055 0.594,70.771 L0.594,116.806 C0.594,122.151 3.595,126.800 8.002,129.145 C10.222,134.634 15.595,138.502 21.875,138.502 C27.507,138.502 32.416,135.386 34.967,130.781 L82.910,130.781 L82.910,130.781 L105.970,130.781 C108.771,135.320 113.786,138.502 119.503,138.502 C128.285,138.502 135.406,131.377 135.406,122.598 C135.406,113.811 128.285,106.689 119.503,106.689 ZM14.564,121.887 C11.766,121.887 9.490,119.603 9.490,116.806 L9.490,70.771 C9.490,67.969 11.766,65.693 14.564,65.693 L76.190,65.693 C77.354,65.693 78.494,66.096 79.396,66.834 L95.549,80.010 C96.738,80.978 97.418,82.409 97.418,83.945 L97.418,116.806 C97.418,119.603 95.141,121.887 92.343,121.887 L14.564,121.887 ZM43.099,6.790 C43.572,10.769 41.016,14.234 37.438,15.192 L32.510,48.641 C32.459,48.996 32.156,49.258 31.799,49.258 L25.304,49.258 C24.865,49.258 24.528,48.865 24.592,48.433 L29.808,13.011 C28.510,11.634 27.722,9.777 27.722,7.738 C27.722,3.179 31.666,-0.455 36.326,0.066 C39.838,0.459 42.682,3.279 43.099,6.790 ZM108.865,39.998 L108.865,44.249 C108.865,46.047 107.409,47.506 105.610,47.506 L99.981,47.506 L99.981,60.852 C99.981,61.216 99.558,61.414 99.281,61.180 L90.924,54.186 C90.734,54.030 90.625,53.791 90.625,53.544 L90.625,47.506 L85.227,47.506 C83.429,47.506 81.972,46.047 81.972,44.249 L81.972,39.998 C81.972,38.198 83.429,36.741 85.227,36.741 L105.610,36.741 C107.409,36.741 108.865,38.200 108.865,39.998 Z" /></svg></div>
                                <h2 className="headline inview animated static delay-1" dangerouslySetInnerHTML={{ __html: t('express.title') }} />
                                <div className="content inview animated static delay-2">{t('express.text')}</div>
                            </div>
                        </div>
                    </section>

                    <section id="smart" className="section-content">
                        <div className="container-fluid">
                            <div className="wrapper">
                                <div className="icons inview animated"><img className="img-fluid" src={HondaIcon} alt={t('smart.title')} /></div>
                                <h2 className="headline inview animated">{t('smart.title')}</h2>
                                <div className="content">
                                    <p className="inview animated delay-1">{t('smart.text')}</p>
                                    {t('smart.apple') !== "" && (
                                        <div className="cta-downloads pt-4 d-flex inview animated static delay-2 justify-content-center">
                                            <a href={t('smart.apple')} className="mx-1" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={AppleBadge} alt="Download Apple App" /></a>
                                            <a href={t('smart.google')} className="mx-1" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={GoogleBadge} alt="Download Google Play" /></a>
                                        </div>
                                    )}
                                    {t('smart.apple') === "" && (
                                        <>
                                            <div className="cta-downloads pt-4 d-flex inview animated static delay-2 justify-content-center">
                                                <img className="img-fluid" src={AppleBadge} alt="Download Apple App" />
                                                <img className="img-fluid" src={GoogleBadge} alt="Download Google Play" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="smart-animation">
                                <div id="red-ball" className="floating"><img className="img-fluid" src={RedBall} alt={t('smart.title')} width="594" height="594" /></div>
                                <div id="blue-ball" className="floating"><img className="img-fluid" src={BlueBall} alt={t('smart.title')} width="185" height="185" /></div>
                                <div id="green-ball" className="floating"><img className="img-fluid" src={GreenBall} alt={t('smart.title')} width="407" height="408" /></div>
                                <div id="phones"><img className="img-fluid" src={Smart} alt={t('smart.title')} width="2158" height="1080" /></div>
                            </div>
                        </div>
                        <div id="merch">
                            <div className="subtext text-center px-3">
                                <h4 className="headline-block mb-3">{t('smart.subtitle')}</h4>
                            </div>
                            <div className="text-center mx-auto">
                                <div className="merch-img">
                                    <img className="img-fluid" src={MerchImg} alt={t('merch.title')} />
                                </div>
                                <div className="mx-3">
                                    <h4 className="mb-3">{t('merch.title')}</h4>
                                    <a href={t('merch.orderlink')} className="btn btn-theme btn-red" target="_blank" rel="noopener noreferrer">{t('merch.order')}</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="opening" className="section-content">
                        <div className="container-xl">
                            <div className="wrapper">
                                <div className="icons inview animated"><img className="img-fluid" src={HondaIcon} alt={t('opening.title')} /></div>
                                <h2 className="headline inview animated">{t('opening.title')}</h2>
                                <div className="content inview animated delay-1">{t('opening.text')}</div>
                            </div>
                            <div className="grid row g-3 inview animated delay-2 static">
                                {OpeningData.map(function (item) {
                                    return (
                                        <div className={`grid-item ${item.col}`} key={item.id}>
                                            <div className={`grid-wrapper ${item.position}`}>
                                                {item.image && (
                                                    <img className="img-fluid" src={`${require(`../assets/${item.image}`)}`} width="1254" height="918" alt={item.title} />
                                                )}
                                                {item.content && (
                                                    <div className="caption">
                                                        <div className="content">
                                                            <h4 className="red">{item.title}</h4>
                                                            <div dangerouslySetInnerHTML={{ __html: item.content }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('dealer.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('dealer.content')}</p>
                    <DealerLoc />
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}; 